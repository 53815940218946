import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertTitle,
	Box,
	Button,
	Center,
	Container,
	FormControl,
	Heading,
	Stack,
	Text,
	VStack,
	HStack,
	useBreakpointValue,
	useToast,
} from '@chakra-ui/react';
import { Formik } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as ReactLink, useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import PasswordField from '../components/PasswordField';
import { register, resetPassword, resetState } from '../redux/actions/userActions';
import TextField from '../components/TextField';
import {FcGoogle} from 'react-icons/fc'
import { useGoogleLogin,GoogleLogin } from '@react-oauth/google';
import axios from 'axios';

const RegistrationScreen = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const redirect = '/';
	const toast = useToast();
	const { loading, error, userInfo } = useSelector((state) => state.user);
	const headingBR = useBreakpointValue({ base: 'xs', md: 'sm' });
	const boxBR = useBreakpointValue({ base: 'transparent', md: 'bg-surface' });

	console.log(error)
	useEffect(() => {
		if (userInfo) {
			navigate(redirect);
			toast({
				description: userInfo.firstLogin ? 'Fiók létrehozva. Üdvözöljük a weboldalon.' : `Üdvözöljük újra ${userInfo.name}`,
				status: 'success',
				isClosable: true,
			});
		}
	}, [userInfo, redirect, error, navigate, toast]);


	const handleGoogleLogin = useGoogleLogin({
		onSuccess: async (response) => {
			const userInfo = await axios
				.get('https://www.googleapis.com/oauth2/v3/userinfo', {
					headers: { Authorization: `Bearer ${response.access_token}` },
				})
				.then((res) => res.data);
			const { sub, email, name, picture } = userInfo;
			dispatch(GoogleLogin(sub, email, name, picture));
		},
	});

	return (
		<Formik
			initialValues={{ email: '', password: '', name: '' }}
			validationSchema={Yup.object({
				name: Yup.string().required('Név megadása szükséges.'),
				email: Yup.string().email('Érvénytelen e-mail').required('Az e-mail cím szükséges.'),
				password: Yup.string()
					.min(5, 'A jelszó túl rövid - legalább 5 karaktert kell tartalmaznia.')
					.required('Jelszó szükséges.'),
				confirmPassword: Yup.string()
					.min(5, 'A jelszó túl rövid - legalább 5 karaktert kell tartalmaznia.')
					.required('Jelszó szükséges.')
					.oneOf([Yup.ref('password'), null], 'A jelszavaknak egyezniük kell'),
			})}
			onSubmit={(values) => {
				dispatch(register(values.name, values.email, values.password));
			}}>
			{(formik) => (
				<Container maxW='lg' py={{ base: '12', md: '24' }} px={{ base: '0', md: '8' }} minH='4xl'>
					<Stack spacing='8'>
						<Stack spacing='6'>
							<Stack spacing={{ base: '2', md: '3' }} textAlign='center'>
								<Heading size={headingBR}>Fiók létrehozása.</Heading>
								<HStack spacing='1' justify='center'>
									<Text color='muted'>Már regisztrált?</Text>
									<Button as={ReactLink} to='/bejelentkezes' variant='link' colorScheme='green'>
									Bejelentkezés
									</Button>
								</HStack>
							</Stack>
						</Stack>
						<Box
							py={{ base: '0', md: '8' }}
							px={{ base: '4', md: '10' }}
							bg={{ boxBR }}
							boxShadow={{ base: 'none', md: 'xl' }}>
							<Stack spacing='6' as='form' onSubmit={formik.handleSubmit}>
								{error && (
									<Alert
										status='error'
										flexDirection='column'
										alignItems='center'
										justifyContent='center'
										textAlign='center'>
										<AlertIcon />
										<AlertTitle>Sajnáljuk!</AlertTitle>
										<AlertDescription>{error}</AlertDescription>
									</Alert>
								)}
								<Stack spacing='5'>
									<FormControl>
										<TextField type='text' name='name' placeholder='Vezeték és keresztnév' label='Teljes név' />
										<TextField type='text' name='email' placeholder='you@example.com' label='Email' />
										<PasswordField type='password' name='password' placeholder='Jelszavad' label='Jelszó' />
										<PasswordField
											type='password'
											name='confirmPassword'
											placeholder='Jelszó újra'
											label='Jelszó újra'
										/>
									</FormControl>
								</Stack>
								<Stack spacing='6'>
									<Button colorScheme='green' size='lg' fontSize='md' isLoading={loading} type='submit'>
										Regisztráljon
									</Button>
									<Button colorScheme='green' size='lg' fontSize='md' isLoading={loading} onClick={()=>handleGoogleLogin()}>
										<FcGoogle />
										Regisztráljon Google-el
									</Button>
								</Stack>
							</Stack>
						</Box>
					</Stack>
				</Container>
			)}
		</Formik>
	);
};

export default RegistrationScreen;