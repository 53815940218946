import { Box, Wrap, Badge, Link, Text, WrapItem, useBreakpointValue } from "@chakra-ui/react";
import { categoryData } from "../categoryData";
import { Link as ReactLink } from "react-router-dom";
import { useState } from "react";
import { CiCirclePlus } from "react-icons/ci";
import { CiCircleMinus } from "react-icons/ci";

const Category = () => {
    const [showAll, setShowAll] = useState(false);
    const isMobile = useBreakpointValue({ base: true, md: false }); // Ellenőrizzük, hogy mobil eszközön vagyunk-e
    const maxVisibleCategories = isMobile ? 35 : categoryData.length; // Mobilon 5, nagy képernyőn pedig az összes

    const toggleCategories = () => {
        setShowAll(!showAll);
    };

    return (
        <Box>
            {/* <Box display='flex' justifyContent='center' alignItems='center' marginY='20px'>
                <Text fontSize='3xl' fontWeight='bold'>Kategóriák</Text>
            </Box> */}
            <Wrap spacing='2' justify='center' mt={5} align='center'>
                {categoryData.slice(0, showAll || !isMobile ? maxVisibleCategories : 5).map((category) => (
                    <Box
                    as={ReactLink}
                    to={category.link}
                        position='relative'
                        className="category-button"
                        key={category.name}
                        display='flex'
                        flexDirection='column'
                        alignItems='center'
                        justifyContent='space-around'
                        p={1} 
                        bg='white'
                        rounded='lg'
                        boxShadow='md'
                        color='green.600'
                        transition='0.3s'
                        _hover={{ backgroundColor: 'green.100' }}
                        w='100px' 
                        h='100px'  
                    >
                        <WrapItem display='flex' justifyContent='center' w='35px' h='35px'>
                            <ReactLink
                                to={category.link}
                                textAlign='center'
                                style={{
                                    textAlign:'center',
                                    width: '100%',
                                    height: '100%',
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <span style={{ fontSize: '16px' }}>{category.svg}</span> 
                            </ReactLink>
                        </WrapItem>
                        <Link
                            fontSize={{ base: '8px', md: '10px' }} 
                            fontWeight='semibold'
                            display='flex'
                            justifyContent='center'
                            textDecoration="none"
                            _hover={{ textDecoration: 'none' }}
                            textAlign='center'
                        >
                            {category.name}
                        </Link>
                        {category.new && (
                            <Badge 
                                textAlign='center'
                                position='absolute'
                                left={{ base: '0px', md: '0px' }}
                                top={{ base: '0px', md: '0px' }}
                                borderTopLeftRadius='5px'
                                w='50px' 
                                fontSize={{ base: '0.3em', md: '0.5em' }} 
                                colorScheme='red'
                            >
                                ÚJDONSÁG
                            </Badge>
                        )}
                    </Box>
                ))}
                {isMobile && ( 
                    <Box 
                        h={{ base: '40px', md: '100px' }} 
                        display='flex' 
                        flexDirection='column' 
                        alignItems='center' 
                        justifyContent='center' 
                        onClick={toggleCategories} 
                        style={{ cursor: 'pointer', transition: 'transform 0.3s' }}
                        _hover={{ boxShadow: 'md' }} 
                    >
                        {showAll ? 
                            <CiCircleMinus style={{ width: '40px', height: '40px', color: 'green.600' }} /> : 
                            <CiCirclePlus style={{ width: '40px', height: '40px', color: 'green.600' }} />
                        }
                        <Text 
                            textAlign='center' 
                            fontWeight='semibold' 
                            fontSize={{ base: '10px', md: '14px' }} 
                            color='green.600'
                            mt={1} 
                        >
                            {showAll ? 'Mutass kevesebbet' : 'Mutass többet'}
                        </Text>
                    </Box>
                )}
            </Wrap>
        </Box>
    );
};

export default Category;
