import { Container } from "@chakra-ui/react";
import { Navigate, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { DeleteIcon } from '@chakra-ui/icons';
import { categoryData } from "../categoryData";
import {
    Badge,
    Box,
    Select,
    Button,
    Flex,
    FormControl,
    FormLabel,
    Input,
    Switch,
    Td,
    Textarea,
    Tr,
    VStack,
    useDisclosure,
    Text,
    Tbody,
    Tooltip,
    Spacer,
    useToast,
    Image
} from '@chakra-ui/react';
import { useRef, useState, useEffect } from 'react';
import { MdOutlineDataSaverOn } from 'react-icons/md';
import { useDispatch } from 'react-redux';
import { deleteProduct, updateProduct } from '../redux/actions/adminActions';
import ConfirmRemovalAlert from "../components/ConfirmRemovalAlert";
import AddNewProduct from "../components/AddNewProduct";
import { uploadProduct } from "../redux/actions/adminActions";
import axios from "axios";
import {
    getProducts,
    resetProductError,
} from '../redux/actions/productActions';
const AdminNewProduct = () => {

    const { userInfo } = useSelector((state) => state.user);
    const location = useLocation();
    const { error, loading } = useSelector((state) => state.admin);
    const { products, productUpdate } = useSelector((state) => state.product);
    const toast = useToast();
    const dispatch = useDispatch()
    const [brand, setBrand] = useState('')
    const [name, setName] = useState('')
    const [category, setCategory] = useState('')
    const [stock, setStock] = useState('')
    const [price, setPrice] = useState('')
    const [productIsNew, setProductIsNew] = useState(false)
    const [visszavalto, setVisszavalto] = useState(false)
    const [description, setDescription] = useState('')
    const [imageOne, setImageOne] = useState('')
    const [imageTwo, setImageTwo] = useState('')
    const [categoryAlt, setCategoryAlt] = useState('')
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [imagePreviewOne, setImagePreviewOne] = useState(null);
    const [imagePreviewTwo, setImagePreviewTwo] = useState(null);
    const [uploadedImage, setUploadedImage] = useState('');
    const [message, setMessage] = useState('');

    useEffect(() => {
        const foundCategory = categoryData.find(cat => cat.link === `/${category}`);
        setSelectedCategory(foundCategory || null);
        if (!foundCategory) setCategoryAlt('');
    }, [category]);



    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append('image', file);

        try {
            const response = await axios.post('/api/upload', formData, {
                headers: { 'Content-Type': 'multipart/form-data' },
            });
            setMessage(response.data.message);
            setUploadedImage(response.data.image); // Ez tartalmazza a fájl URL-jét
        } catch (error) {
            console.error('Hiba történt:', error);
            setMessage('Hiba történt a feltöltés során.');
        }
    };

    const createNewProduct = () => {
        dispatch(uploadProduct({
            brand,
            name,
            category,
            categoryAlt,
            stock,
            price,
            images: [imageOne, imageTwo],
            productIsNew,
            description,
            visszavalto
        }))
    }

    useEffect(() => {

        if (productUpdate) {
            toast({
                description: 'A termék frissítve lett.',
                status: 'success',
                isClosable: true,
            });
        }
    }, [dispatch, toast, productUpdate]);



    return userInfo && userInfo.isAdmin ? (

        <Container maxW='container.md' my={5}>
            <Text fontSize='xl' fontWeight='bold' my={10} textAlign='center'>Új termék hozzáadása</Text>

            <div>
                <input type="file" onChange={handleFileUpload} />
                {message && <p>{message}</p>}
                {uploadedImage && (
                    <p>
                        Fájl sikeresen feltöltve: <a href={uploadedImage}>{uploadedImage}</a>
                    </p>
                )}
            </div>

            <Text fontSize='sm'>Leírás</Text>
            <Textarea value={description} w='100%' h='120px' onChange={(e) => setDescription(e.target.value)} placeholder="description" size='sm' />


            <Text fontSize='sm'>Márka</Text>
            <Input size='sm' value={brand} onChange={(e) => setBrand(e.target.value)} placeholder="Apple or Samsung" />
            <Text fontSize='sm'>Név</Text>
            <Input size='sm' value={name} onChange={(e) => setName(e.target.value)} placeholder="Samsung s23" />


            <Text fontSize='sm'>Kategória</Text>
            <Select
                placeholder="Kategóriák"
                value={category}
                onChange={(e) => {
                    const selectedCategoryValue = e.target.value.replace('/', ''); // Töröljük a '/' jelet
                    setCategory(selectedCategoryValue);
                    setCategoryAlt('');
                }}
            >
                {categoryData.map((categoryItem) => (
                    <option key={categoryItem.name} value={categoryItem.link.replace('/', '')}>
                        {categoryItem.name}
                    </option>
                ))}
            </Select>

            <Text fontSize='sm'>Kategória II.</Text>
            {selectedCategory && selectedCategory.categoryalt && (
                <Select
                    placeholder="Alkategóriák"
                    value={categoryAlt}
                    onChange={(e) => {
                        const selectedAltCategoryValue = e.target.value.split('/').pop(); // A '/' utáni részt kapjuk meg
                        setCategoryAlt(selectedAltCategoryValue); // Alt kategória frissítése
                    }}
                >
                    {selectedCategory.categoryalt.map((altCategory) => (
                        <option key={altCategory.name} value={altCategory.link.split('/').pop()}>
                            {altCategory.name}
                        </option>
                    ))}
                </Select>

            )}



            <Text fontSize='sm'>Ár</Text>
            <Input size='sm' value={price} onChange={(e) => setPrice(e.target.value)} placeholder="299.99" />


            <Text fontSize='sm'>Darab</Text>
            <Input size='sm' value={stock} onChange={(e) => setStock(e.target.value)} />
            <Text fontSize='sm'>Jelvények</Text>
            <FormControl display='flex' alignItems='center'>
                <FormLabel htmlFor="productIsNewFlag" mb='0' fontSize='sm'>
                    Akciós
                    <Badge rounded='full' px='1' mx='1' fontSize='0.8em' colorScheme='green'>AKCIÓ</Badge>
                    jelvény hozzáadása?
                </FormLabel>
                <Switch id="productIsNewFlag" onChange={() => setProductIsNew(!productIsNew)} isChecked={productIsNew} />
            </FormControl>
            <FormControl display='flex' alignItems='center'>
                <FormLabel htmlFor="visszavalto" mb='0' fontSize='sm'>
                    Visszaváltó
                    <Badge rounded='full' px='1' mx='1' fontSize='0.8em' colorScheme='green'>VISSZAVÁLTÓ</Badge>
                    jelvény hozzáadása?
                </FormLabel>
                <Switch id="visszavalto" onChange={() => setVisszavalto(!visszavalto)} isChecked={visszavalto} />
            </FormControl>

            <VStack>
                <Button variant='outline' w='160px' colorScheme="cyan" onClick={createNewProduct}><Text ml='2'>Save Product</Text></Button>
            </VStack>




        </Container>
    ) : (
        <Navigate to='/' replace={true} state={{ from: location }} />
    );


}

export default AdminNewProduct;