import React, { useState } from 'react';
import {
    Button,
    Image,
    Text,
    Checkbox,
    AlertDialog,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogBody,
    AlertDialogHeader,
    AlertDialogOverlay,
} from '@chakra-ui/react';
import { useDispatch } from 'react-redux';


const Szabadsag = ({ isOpen, onClose }) => {



    const dispatch = useDispatch();
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };



    return (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
                        Kedves Vásárlóink!
                    </AlertDialogHeader>

                    <AlertDialogBody>
                        <Text textAlign="center">
                            Szeretnénk minden kedves vásárlónknak Boldog Új Évet kívánni <br />
                            <br /> <b>Január 1.-én</b> és <b>Január 2.-án</b>  az üzletünk zárva lesz ! <br /> <br />
                            Webshopunk <b>Január 6.-tól</b> vállal ismét kiszállítást <br /> <br />
                            Köszönjük megértésüket és türelmüket az ünnepi időszakban!
                        </Text>
                    </AlertDialogBody>

                    <AlertDialogFooter>
                        <Button onClick={onClose}>Bezár</Button>
                        <Button colorScheme="red" onClick={onClose} ml={3}>
                            Értem
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>


            </AlertDialogOverlay>
        </AlertDialog>
    );
};

export default Szabadsag;
