import axios from 'axios';
import { setError, setLoading, setShippingCosts, cartItemAdd, cartItemRemoval, clearCart,setPacking } from '../slices/cart';

export const addCartItem = (id, qty) => async (dispatch) => {
	dispatch(setLoading(true));
	try {
		const { data } = await axios.get(`/api/products/${id}`);
		const itemToAdd = {
			id: data._id,
			name: data.name,
			subtitle: data.subtitle,
			image: data.images[0],
			price: data.price,
			stock: data.stock,
			brand: data.brand,
			qty,
			stripeId: data.stripeId,
		};

		dispatch(cartItemAdd(itemToAdd));
	} catch (error) {
		dispatch(
			setError(
			  error.response && error.response.data
				? error.response.data
				: error.message
				? error.message
				: 'Egy ismeretlen hiba történt.'
			)
		  );
	}
};

export const removeCartItem = (id) => async (dispatch) => {
	dispatch(setLoading(true));
	dispatch(cartItemRemoval(id));
	dispatch(setPacking(false))
};

export const setShipping = (value) => async (dispatch) => {
	dispatch(setShippingCosts(value));
};

export const setPackingValue = (value) => async (dispatch) => {
	dispatch(setPacking(value));
};

export const resetCart = () => (dispatch) => {
	dispatch(clearCart);
};